import Checklist from "components/Button/Checklist";
import {
  Input,
  Label
} from "reactstrap";
import Select from 'react-select';
const { getUserData } = require("variables/common");

function SelecttWithUserTier({value,
    disabled,
    options,
    onChange,
    subFunction,
    subFunctionCheck,
    label,
    checkText,
    checkValue,
    checkValueOnChange}) {
    const userData = getUserData()
    const mitraType = userData.mitraType
    let localFunction = subFunction
    if (typeof subFunction !== 'function') {
        localFunction = () => {
            console.log('No Function in InputWithUserTier')
        }
    }
    let localCheckFunction = subFunctionCheck
    if (typeof subFunctionCheck !== 'function') {
        localCheckFunction = () => {
            console.log('No Function in Check InputWithUserTier')
        }
    }
    return (mitraType === 'SUPERADMIN') ? (
        <>
        <Label>{label || 'Default Label'}</Label>
        <Select
            // className="react-select"
            // classNamePrefix="react-select"
            placeholder={label || "Label"}
            value={value}
            isDisabled = {!disabled}
            options={options}
            onChange={ async(e) => {
                onChange(e)
                await localFunction(e)
            } } />
        <Checklist
            value = {checkValue}
            checked = {checkValue}
            onChange = {async (e) => {
                checkValueOnChange(e.target.checked)
                await localCheckFunction(e.target.checked)
            }}
            text = {checkText}
        />
        </>) : (
        <Input
            placeholder={label || "Label"}
            value={value.value}
        />
        )
    }

export default SelecttWithUserTier