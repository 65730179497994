/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Login from "./../../../Main/LoginService"
import {setUserSession,
  addUserData,
  addUserPermission,
  addDashboard,
  removeUserSession} from "variables/common"
// reactstrap components
import {
  Card,
  CardBody,
  // CardHeader,
  // CardFooter,
  Form,
  Container,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button
} from "reactstrap";

// core components
// import nowLogo from "assets/img/now-logo.png";
import artaLogo from "assets/img/Artamedia-logo.png"
import authLogo from "assets/img/g-auth-logo.png"
import bgImage from "assets/img/pattern3.jpg";
import { CatchLoginError } from "Main/Exceptions/exceptions";
import CustomerService from "Main/CustomerService";
import { useFormInput } from "Main/Utils"
import LoadingScreen from "components/Loader/LoadingScreen";

function LoginPage(props) {
  const LoginService = new Login()
  const [EmailFocus, setEmailFocus] = useState(false)
  const [PasswordFocus, setPasswordFocus] = useState(false)
  const [passwordShown, setPasswordShown] = useState(false)
  const [otpFilled, setOTPFilled] = useState(false)
  const [isLoad, setIsLoad] = useState(false)
  const [isUseTwoFA, setUseTwoFA] = useState(false)

  const [loginUsrIdentifier, setUsrIdentifier] = useState('')
  const [loginKey, setLoginKey] = useState('')
  const [otp, setOTP] = useState('')
  const email = useFormInput('')
  const password = useFormInput('')

  const fetch = async() => {
      const customerService = new CustomerService()
      const dashboard = await customerService.loadDashboard()
      addDashboard(dashboard.data)
  }

  useEffect(() => {
    removeUserSession()
  }, [])

  // Handle first Layer of Authentication
  const handleKeyRequest = async() => {
    try {
      setIsLoad(true)
      LoginService.verifyLoginPayload(email, password)

     // setLoading(true);
      const result = await LoginService.requestToken(email, password)
      const { useTwoFA, key, usrIdentifier } = result.data

      setUsrIdentifier(usrIdentifier)
      setLoginKey(key)
      if (!useTwoFA) {
        setIsLoad(false)
        await handleLogin(key, usrIdentifier)
        return true
      }
      setUseTwoFA(true)
      setIsLoad(false)
      return true
    } catch (error) {
      setIsLoad(false)
      return CatchLoginError(error)
    }
  }

   // handle button click of login form
  const handleLogin = async(key, usrIdentifier) => {
    try {
      setIsLoad(true)
      setOTPFilled(false)
     // setLoading(true);
      const dat = await LoginService.login(loginUsrIdentifier || usrIdentifier, loginKey || key, otp)

      const result = dat.data
      const user = dat.user
      const permission = dat.permission
      setUserSession(result.accesstoken, result.refreshtoken, {id: result.id, username: result.username, email: email});
      addUserData(user)
      addUserPermission(permission)
      await fetch()
      setIsLoad(false)
     // setLoading(false);
      props.history.push('/admin')
    } catch (error) {
      setOTPFilled(false)
      setIsLoad(false)
      return CatchLoginError(error)
    }
  }

  // const handlerMakeAccount = () => {
  //   props.history.push('/auth/register-page')
  // }

  useEffect(() => {
    document.body.classList.add("login-page");
    return function cleanup() {
      document.body.classList.remove("login-page");
    };
  }, []);

  const togglePassword = () => {
      // When the handler is invoked
      // inverse the boolean state of passwordShown
      setPasswordShown(!passwordShown);
  };

  const children = (
    <>
      <div className="login-page">
          <Container>
            <Col xs={12} md={4} lg={4} className="ml-auto mr-auto">
              <Form>
                <Card className="p-3 mb-2 card-login card-plain">
                {/* <Card className="card-login bg-dark rounded"> */}
                  {/* <CardHeader>
                    <div className="logo-container bg-secondary rounded opacity-75">
                    <div className="logo-container">
                      <img src={artaLogo} alt="now-logo" />
                    </div>
                  </CardHeader> */}
                  <CardBody>
                    <div className="logo-container">
                      {!isUseTwoFA ? <img src={artaLogo} alt="artamedia-logo" /> : <img src={authLogo} alt="auth-logo" />}
                    </div>
                    {!isUseTwoFA ?
                      (<>
                          <InputGroup
                            className={"no-border form-control-lg" +
                              (EmailFocus ? "input-group-focus" : "")}>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="now-ui-icons users_circle-08" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              className="text-white"
                              type="text"
                              placeholder="Email..."
                              {...email}
                              onFocus={() => setEmailFocus(true)}
                              onBlur={() => setEmailFocus(false)} />
                        </InputGroup>
                        <InputGroup
                          className={"no-border form-control-lg " +
                            (PasswordFocus ? "input-group-focus" : "")}>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="now-ui-icons text_caps-small" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type={passwordShown ? "text" : "password"}
                              className="text-white"
                              placeholder="Password"
                              {...password}
                              onFocus={() => setPasswordFocus(true)}
                              onBlur={() => setPasswordFocus(false)} />
                          </InputGroup>
                            <a className="btn btn-link text-light"
                              onClick={togglePassword}>Show Password</a>
                            <Button
                              block
                              size="lg"
                              className="mb-3 btn-round text-white font-weight-bold bg-dark"
                              onClick={handleKeyRequest}
                            >Sign In</Button>
                          </>
                        ) : (<>
                          <InputGroup
                            className={"no-border form-control-lg" +
                              (EmailFocus ? "input-group-focus" : "")}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="now-ui-icons text_caps-small" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              className="text-white"
                              type="number"
                              placeholder="Security Code..."
                              value = {otp}
                              onChange={(e) => {
                                if (e.target.value.length <= 6) {
                                  setOTP(e.target.value)
                                  setOTPFilled(true)
                                }
                              }}
                              onFocus={() => setEmailFocus(true)}
                              onBlur={() => setEmailFocus(false)} />
                        </InputGroup>
                        <br></br>
                        <Button
                              block
                              size="lg"
                              className="mb-3 btn-round text-white font-weight-bold bg-dark"
                              disabled = {!otpFilled}
                              onClick={() => handleLogin(loginUsrIdentifier, loginKey, otp)}
                            >Continue</Button>
                          </>
                        )
                    }
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Container>
        </div>
    </>
  );

  return (
    <>
      <div className="content">
            <LoadingScreen
              text = {'Loading'}
              loading = {isLoad}
              children={children} />
      </div>
      <div
        className="full-page-background"
        style={{ backgroundImage: "url(" + bgImage + ")" }}
      />
    </>
  )
}


export default LoginPage;
