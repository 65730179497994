import React, { useState, useEffect } from "react";
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle
} from "reactstrap";

// core components
// import Switch from "react-switch";
import ReactTable from "components/ReactTable/ReactTable.js";
import swal from "sweetalert";
import LoadingScreen from "components/Loader/LoadingScreen";
import defaultColumn from "./Components/DefaultColumn";
import defaultMenu from "./Components/DefaultMenu";
import TotalFooter from "./Components/TotalFooter";


function CommonList({load, dataState, Add, getData, columns, header, menu, total, addDisabled, loadingLabel = 'loading'}) {
  const [page, pageOnChange] = useState(0)
  const setPageState = (pages) => pageOnChange(pages)

  useEffect( () => {
    if (!getData) {
      console.log('no-fetch-data')
      return
    }
    getData()
  }, [])

  try {
    const defaultHeader = 'Logistic Goods Manager'

    const childElement = (
      <>
        <ReactTable
          key={1}
          data={!dataState ? [] : dataState}
          pageState = {page}
          pageStateSet = {setPageState}
          columns= {columns || defaultColumn}
        />
      </>
    )

    return (
      <Card className="shadow-md">
        <CardHeader>
          <CardTitle tag="h6" className="title">{header || defaultHeader}</CardTitle>
        </CardHeader>
        <CardBody>
          {defaultMenu(Add, menu, addDisabled)}
          <LoadingScreen
            key = {2}
            text = {loadingLabel}
            loading = {load}
            children={childElement}
          />
          <TotalFooter
            key = {3}
            value={total}
          />
        </CardBody>
      </Card>
    )
  } catch (error) {
    swal('Failed', error.message, 'warning')
    return <p>{error.message}</p>
  }
}

export default CommonList;
