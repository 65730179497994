import { Card, CardBody, CardHeader, CardTitle, Col, Row, Table } from "reactstrap"
import { PriceFormat } from "variables/common"
import defaultImg from "assets/img/loading.gif";

function SellingTable ({data, total, title = "Best Selling Products", currency = 'idr'}) {
    return (
      <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h6">{title}</CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive className="table-shopping">
                  <thead>
                    <tr>
                      <th className="text-center" />
                      <th>PRODUK</th>
                      <th className="text-right">UPLINK</th>
                      <th className="text-right">DOWNLINK</th>
                      <th className="text-right">HARGA</th>
                      <th className="text-right">QTY</th>
                      <th className="text-right">TOTAL</th>
                    </tr>
                  </thead>
                  <tbody>
                      {data.map((e, i) => {
                        return (
                          <>
                          <tr key={i}>
                          <td>
                            <div className="img-container">
                              <img src={ e.image || defaultImg} className="img-thumbnail list_img" alt="..." />
                            </div>
                          </td>
                          <td className="td-name">
                              <a href="#jacket">{e.name}</a>
                              <br />
                              <small>by {e.owner}</small>
                            </td>
                            <td  className="td-number">{`${e.uplink}${e.measurement}`}</td>
                            <td  className="td-number">{`${e.downlink}${e.measurement}`}</td>
                            <td className="td-number">
                              {PriceFormat(e.price, currency)}
                            </td>
                            <td className="td-number">{e.count}</td>
                            <td className="td-number">
                              {PriceFormat((Number(e.price) * Number(e.count)), currency)}
                            </td>
                            </tr></>
                        )
                      })}
                    <tr>
                      <td colSpan="5" />
                      <td className="td-total">Total</td>
                      <td className="td-price">
                        {PriceFormat(total, currency)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
    )
}

export default SellingTable