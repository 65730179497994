import React, { useEffect } from "react";
import LoadingScreen from "components/Loader/LoadingScreen";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  FormGroup,
  Label
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import BillingService from "Main/BillingService";
import Select from "react-select"
import { useState } from "react";
import {getRefreshToken, getBillingData,
  putBilling,
  // addBillingData, addItemOptions,
  // addAddressOptions, addStatusOptions, addCustomerOptions,
  GetBillingStatus,
  getRouterSelect,
  getAddressOptions,
  getOwner,
  getUserPermission} from "./../../../variables/common"
import { CatchError } from "Main/Exceptions/exceptions";
import swal from "sweetalert";
import CustomerService from "Main/CustomerService";
import { DateTimestr } from "Main/Utils";
import RouterService from "Main/RouterService";
import { verifySuperAdmin } from "Main/Utils";
import SubscriptionAddOnService from "Main/Add-On/SubscriptionAddOnService";
import BasicInput from "components/Input/BasicInput";
import Checklist from "components/Button/Checklist";
import SelectBasic from "components/Input/SelectBasic";
import { addSubscriptionAddOnList } from "variables/Add-On/SubscriptionAddOn";
import { getSubscriptionAddOnList } from "variables/Add-On/SubscriptionAddOn";
import SubscriptionAddOnList from "../Add-On/SubscriptionAddOn/SubscriptionAddOnList";
import { addSubscriptionAddOnData } from "variables/Add-On/SubscriptionAddOn";
import { payloadTrash } from "variables/Add-On/SubscriptionAddOn";

function BillingView(props) {
  const pages = 'billing'
  const permission = getUserPermission()
  const Permission = permission.find(data => data.Role === pages) || ''
  const billingService =  new BillingService()
  const customerService = new CustomerService()
  const routerService = new RouterService()
  const subscriptionAddOnService = new SubscriptionAddOnService()
  const refreshToken = getRefreshToken()
  const BillingData = getBillingData()
  var statusOptions = GetBillingStatus()
  const ownerData = getOwner()
  const [routerSelectOption, routerSelectOptionOnChange] = useState(getRouterSelect())
  const [selectItemOptions, selectItemOptionsOnChange] = useState([])
  const [subscriptionAddOnList, setSubscriptionAddOnList] = useState(getSubscriptionAddOnList())
  const [loadingAddOn, setLoadingAddOn] = useState(false)
  const id = BillingData.Subscription.networkId
  const name = BillingData.Subscription.subscription
  const owner = ownerData.find((own) => own.value === BillingData.Subscription.owner)
  const customer = BillingData.Subscription.customerName
  const customerid = BillingData.customer.id
  const category = BillingData.customer.category
  const [selectAddressOptions, selectAddressOptionsOnChange] = useState(getAddressOptions() || [])
  const [item , itemOnChange] = useState(selectItemOptions.find(item => item.value === BillingData.Subscription.itemId || []))
  const [address , addressOnChange] = useState(selectAddressOptions.find(addr => addr.label === BillingData.Subscription.addressName || []))
  const [status , statusOnChange] = useState(statusOptions.find(stat => stat.value === BillingData.Subscription.status))
  const [generate, generateOnChange] = useState(false)
  const [loading, loadingOnChange] = useState(false)
  const [trash, setTrash] = useState(false)
  const [start, setStart] = useState(DateTimestr(BillingData.Subscription.startSubscribe))
  const [end, setEnd] = useState(DateTimestr(BillingData.Subscription.endSubscribe))
  const [router, routerOnChange] = useState(routerSelectOption.find((rtr) => rtr.value === BillingData.Subscription.routerid) || [])
  const { mitraSuper } = verifySuperAdmin()
  const [ppoeUser, setPpoeUser] = useState(BillingData.Subscription.pppoeUser)
  const [pppoePassword, setPppoePassword] = useState(BillingData.Subscription.pppoePassword)

  useEffect(() => {
    getRouter(owner.mitraid)
    getAddress(customerid, owner.mitraid)
  }, [])

  const getRouter = async(mitraid) => {
    const dat = await routerService.getRouterSelect(mitraid)
    const result = dat.data
    routerSelectOptionOnChange(result)
    const selected = result.find((rtr) => rtr.value === BillingData.Subscription.routerid) || []
    routerOnChange(selected)
    await getItem(selected.value, owner.mitraid, category)
    return result
  }

  const getItem = async(routerid, mitraid, category) => {
    const dat = await customerService.getItemSelect(routerid, mitraid, category)
    const result = dat.data
    selectItemOptionsOnChange(result)
    itemOnChange(result.find(item => item.value === BillingData.Subscription.itemId) || [])
    return result
  }

  const getAddress = async(customerId, mitraid) => {
    selectAddressOptionsOnChange([])
    const dat = await customerService.getAddressSelect(customerId, mitraid)
    const result = dat.data
    selectAddressOptionsOnChange(result)
    addressOnChange(result.find(addr => addr.label === BillingData.Subscription.addressName) || [])
    return result
  }

  const fetchSubscriptionAddOn = async (trashView = false) => {
    const len = 'ALL'
    setLoadingAddOn(true)
    const result = await subscriptionAddOnService.getAllData(id, len, trashView)
    const addOnList = await result.data
    if (!trash) {
      addSubscriptionAddOnList(addOnList)
      setSubscriptionAddOnList(addOnList)
      setLoadingAddOn(false)
      return addOnList
    }
    setSubscriptionAddOnList(addOnList)
    setLoadingAddOn(false)
    return addOnList
  }

 const deleteAddOn = async(id) => {
      return swal("Delete", "Data Tidak Dapat di Recover", "info", {
            buttons: {
            cancel: 'Batal',
            delete: {text: "Hapus", value: 'del' }
          }
        }
      ).then(async(value) => {
          switch (value) {
            case 'del':
              try {
                setLoadingAddOn(true)
                const result = await subscriptionAddOnService.deleteData(id)
                await swal(result.status, result.message, 'success')
                await fetchSubscriptionAddOn()
                setLoadingAddOn(false)
                return true
              } catch (error) {
                setLoadingAddOn(false)
                return await CatchError(error, props, refreshToken)
              }

            default:
              await fetchSubscriptionAddOn()
              window.location.reload()
              return false
          }
        })
      }

    const viewAddOn = async()=> {
      try {
        const dat = await subscriptionAddOnService.getData(id)
        const result = dat.data
        addSubscriptionAddOnData(result)
        props.history.push('/admin/subscription-add-on-update')
      } catch (error) {
        return await CatchError(error, props, refreshToken)
      }
    }

    const AddSubscriptionAddOn = () => {
      props.history.push('/admin/subscription-add-on-page')
    }

  const putBillingData = async() => {
    swal("Update", "Data Tidak Dapat di Recover", "info", {
                buttons: {
                update: {text: "Update", value: 'up' },
                cancel: 'Cancel'
              }
            }
      ).then(async(value) => {
        switch (value) {
          case 'up':
            loadingOnChange(true)
            try {
              const payload = putBilling(owner.mitraid, customerid, item.value,
                            address.value, status.value, generate, ppoeUser, pppoePassword)

              const result = await billingService.putBilling(id, payload)
              loadingOnChange(false)
              swal(result.status, result.message, 'success').then(() => {
                props.history.push('/admin/Billing-list')
              })
            } catch (error) {
              loadingOnChange(false)
              return await CatchError(error, props, refreshToken)
            }
            break;

          default:
            break;
        }
      })
    }

     const sendToTrash = async (id) => {
      return swal("Trash", "Kirim ke Sampah", "info", {
        buttons: {
          cancel: 'Cancel',
          delete: {text: "Delete", value: 'del' }
        }
      }
      ).then(async(value) => {
        switch (value) {
          case 'del':
            try {
              setLoadingAddOn(true)
              const payload = payloadTrash(id, true)
              const result = await subscriptionAddOnService.softDelete(payload)
              await swal('Success', result.message, 'success')
              await fetchSubscriptionAddOn()
              setLoadingAddOn(false)
              return true
            } catch (error) {
              setLoadingAddOn(false)
              return await CatchError(error, props, refreshToken)
            }

          default:
            await fetchSubscriptionAddOn()
            return false
        }
      })
  }

  const recoverTrash = async (id) => {
    return swal("Recover", "Recover Data", "info", {
      buttons: {
        cancel: 'Cancel',
        delete: {text: "Delete", value: 'del' }
      }
    }
    ).then(async(value) => {
      switch (value) {
          case 'del':
            try {
              setLoadingAddOn(true)
              const payload = payloadTrash(id, false)
              const result = await subscriptionAddOnService.softDelete(payload)
              await swal('Success', result.message, 'success')
              await fetchSubscriptionAddOn()
              setLoadingAddOn(false)
              return true
            } catch (error) {
              setLoadingAddOn(false)
              return await CatchError(error, props, refreshToken)
            }

          default:
            await fetchSubscriptionAddOn()
            return false
      }
    })
  }

  const children = (
    <><div className="content">
          <Row>
          <Col md="12">
              <SubscriptionAddOnList
                fetch = {fetchSubscriptionAddOn}
                add = {AddSubscriptionAddOn}
                view = {viewAddOn}
                deleted = {deleteAddOn}
                loaded = {loadingAddOn}
                dataTable = {subscriptionAddOnList}
                sendToTrash = {sendToTrash}
                recoverTrash = {recoverTrash}
                trash = {trash}
                setTrash = {setTrash}
              />
              <Card>
                <CardHeader>
                  <h5 className="title">{name}</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <FormGroup>
                        <BasicInput
                          label = "Mitra"
                          value={owner.value}
                          />
                        <div>
                          <BasicInput
                            label = "Customer"
                            value={customer}
                            />
                          <Label>Customer Category {category}</Label>
                        </div>
                        <Label>Lokasi Router</Label>
                            <div>
                            <Select
                              // className="react-select"
                              // classNamePrefix="react-select"
                              placeholder="Router Location"
                              name="singleSelect"
                              value = {router}
                              options={routerSelectOption}
                              onChange={(value) => {routerOnChange(value)
                                getItem(value.value, owner.mitraid, category)
                                itemOnChange('')
                                }
                              }
                            />
                            {!router.status ? (
                              <label className="error">
                                Router Offline
                              </label>
                            ) : <label className="success">
                                Router Online
                              </label>}
                          </div>
                          <SelectBasic
                            label = {"Produk Layanan"}
                            value = {item}
                            option={selectItemOptions}
                            onChange={(value) => {
                              itemOnChange(value)
                            }}
                        />
                        <SelectBasic
                            label = {"Alamat"}
                            value = {address}
                            option={selectAddressOptions}
                            onChange={(value) => {
                              addressOnChange(value)
                            }}
                        />
                        <SelectBasic
                            label = {"Status"}
                            value = {status}
                            isDisabled = {!mitraSuper}
                            option = {statusOptions}
                            onChange = {(value) => statusOnChange(value)}
                        />
                        <BasicInput
                          label = "PPPOE User"
                          value={ppoeUser}
                          onChange = {(value) => setPpoeUser(value)}
                        />
                        <BasicInput
                          label = "PPPOE Password"
                          value={pppoePassword}
                          onChange = {(value) => setPppoePassword(value)}
                        />
                        <BasicInput
                          label = "Mulai Subscriptions"
                          value={start}
                          disabled
                        />
                        <BasicInput
                          label = "Akhir Subscriptions"
                          value={end}
                          disabled
                        />
                        <BasicInput
                          label = {'Set Mulai Subscription'}
                          type = {"date"}
                          value = {start}
                          disabled
                          onChange = {setStart}
                        />
                        <BasicInput
                          label = {'Set Akhir Subscription'}
                          type = {"date"}
                          value = {end}
                          disabled
                          onChange = {setEnd}
                        />
                        </FormGroup>
                      </Col>
                      </Row>
                      <Checklist
                        text = {"Generate PPPOE"}
                        value = {generate}
                        checked = {generate}
                        onChange = {e => generateOnChange(e.target.checked)}
                      />
                  </Form>
                  <Button onClick = {putBillingData}
                    color="info"
                    disabled = {!Permission.Update}>Update</Button>
                </CardBody>
              </Card>
            </Col>
        </Row>
      </div>
    </>
  );
return (
    <>
    <PanelHeader size="sm" />
    <LoadingScreen children={children} loading = {loading}/>
    </>
  );
}


export default BillingView;
