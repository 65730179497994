// return the user data from the session storage
const identified = ['paymentstatus']
const [statusPayment] = identified
export const HTMLParse = (html) => {
  var parser = new DOMParser()
  const docHtml = parser.parseFromString(html, 'text/html')
  return docHtml
}

export const getData = (name) => {
  const data = sessionStorage.getItem(name)
  try {
    if (!data) {
    return []
  } else {
    return JSON.parse(data)
  }
  } catch (error) {
    return []
  }
}

export function decreaseFloatPrecission (strFloat, precission = 4) {
  const result = parseFloat(strFloat).toFixed(precission)
  return parseFloat(result)
}

export function renderMainDeviceInventoryTable(dataset, renderTable /* Function */, viewFunc /* Function */, deleteFunc /* Function */, viewPermission /* Function */, deletePermission /* Function */) {
   return Object.entries(dataset).map((data) => {
     return {
       title: data[0],
       data: renderTable(data[1], viewFunc, deleteFunc, viewPermission, deletePermission)
     }
   })
}

export const setData = (name, payload) => {
    sessionStorage.setItem(name, JSON.stringify(payload))
}

export const getUser = () => {
  const userStr = sessionStorage.getItem('user');
  if (!userStr) return null;
  else return JSON.parse(userStr);
}

export const getUserSelect = () => {
  const userStr = sessionStorage.getItem('userselect');
  if (!userStr) return [];
  else return JSON.parse(userStr);
}

// return the token from the session storage
export const getAccessToken = () => {
  const token = localStorage.getItem('accesstoken');
  return token
}

export const getRefreshToken = () => {
  const token = localStorage.getItem('refreshtoken');
  return token
}

// remove the token and user from the session storage
export const removeUserSession = () => {
  sessionStorage.clear()
  localStorage.clear()
}

// set the token and user from the session storage
export const setUserSession = (accessToken, refreshToken, payload) => {
  localStorage.setItem('accesstoken', accessToken);
  localStorage.setItem('refreshtoken', refreshToken);
  sessionStorage.setItem('user', JSON.stringify(payload));
}

export const refreshUserSession = (accessToken, refreshToken) => {
  localStorage.setItem('accesstoken', accessToken);
  localStorage.setItem('refreshtoken', refreshToken);
}

export const setAccessToken = (token) => {
  localStorage.setItem('accesstoken', token);
}

export const addUserData = (payload) => {
  setData('userData', payload)
}

export const addUserSelect = (payload) => {
  setData('userselect', payload)
}

export const addCustomerData = (payload) => {
  setData('customerData', payload)
}

export const addCustomerList = (payload) => {
  setData('customerList', payload)
}

export const getCustomerList = () => {
  return getData('customerList')
}

export const addCustomerMitraList = (payload) => {
  setData('customerMitraList', payload)
}

export const getCustomerMitraList = () => {
  return getData('customerMitraList')
}

export const addUserPermission = (payload) => {
  setData('userpermission', payload)
}

export const getUserPermission = () => {
  return getData('userpermission')
}

export const addCustomerName = (payload) => {
  setData('customerName', payload)
}

export const getCustomerName = () => {
  return getData('customerName')
}

export const getUserData = () => {
  const data = sessionStorage.getItem('userData')
  if (!data) {
    return []
  } else {
    return JSON.parse(data)
  }
}

export const getProvince = () => {
  return getData('province')
}

export const addProvince = (payload) => {
  setData('province', payload)
}

export const getCity = () => {
  return getData('city')
}

export const addCity = (payload) => {
  setData('city', payload)
}

export const GetSubDistrict = () => {
  return getData('subDistrict')
}

export const addSubDistrict = (payload) => {
  setData('subDistrict', payload)
}

export const getUrban = () => {
  return getData('urban')
}

export const addUrban = (payload) => {
  setData('urban', payload)
}

export const addCustomerGroup = (payload) => {
  setData('customerGroup', payload)
}

export const getCustomerGroup = () => {
  return getData('customerGroup')
}

export const addCustomerGroupRaw = (payload) => {
  setData('customerGroupRaw', payload)
}

export const getCustomerGroupRaw = () => {
  return getData('customerGroupRaw')
}

export const getOwner = () => {
  return getData('owner')
}

export const getBillingList = () => {
  return getData('billinglist')
}

export const addBillingData = (payload) => {
  setData('billing', payload)
}

export const addInvoiceList = (payload) => {
  setData('invoiceList', payload)
}

export const addInvoiceData = (payload) => {
  setData('invoiceData', payload)
}

export const getBillingData = () => {
  return getData('billing')
}

export const addBillingList = (payload) => {
  setData('billinglist', payload)
}

export const addCustomerOptions = (payload) => {
  setData('customerOptions', payload)
}

export const addItemOptions = (payload) => {
  setData('itemOptions', payload)
}

export const addAddressOptions = (payload) => {
  setData('addressOptions', payload)
}

export const addBillingOptions = (payload) => {
  setData('billingOptions', payload)
}


export const addStatusOptions = (payload) => {
  setData('statusOptions', payload)
}

export const addStatusOptionsRaw = (payload) => {
  setData('statusOptionsRaw', payload)
}

export const addBillingAddressData = (payload) => {
  setData('billingAddress', payload)
}

export const getBillingAddressData = () => {
  return getData('billingAddress')
}

export const addBillingAddressList = (payload) => {
  setData('billingAddresslist', payload)
}

export const getInvoiceList = () => {
  return getData('invoiceList')
}

export const getInvoiceData = () => {
  return getData('invoiceData')
}

export const getBillingAddressList = () => {
  return getData('billingAddresslist')
}

export const getCustomerOptions = () => {
  return getData('customerOptions')
}

export const getBillingOptions = () => {
  return getData('billingOptions')
}

export const getStatusOptions = () => {
  return getData('statusOptions')
}

export const getStatusOptionsRaw = () => {
  return getData('statusOptionsRaw')
}

export const getAddressOptions = () => {
  return getData('addressOptions')
}

export const getItemOptions = () => {
  return getData('itemOptions')
}

export const addOwner = (payload) => {
  setData('owner', payload)
}

export const addItemList = (payload) => {
  setData('itemlist', payload)
}

export const getItemList = () => {
  return getData('itemlist')
}

export const addItemRouterList = (payload) => {
  setData('itemRouterlist', payload)
}

export const getItemRouterList = () => {
  return getData('itemRouterlist')
}

export const addMitra = (payload) => {
  setData('userMitra', payload)
}

export const getMitra = () => {
  return getData('userMitra')
}

export const addItemData = (payload) => {
  setData('item', payload)
}

export const getItemData = () => {
  return getData('item')
}

export const addItemMeasurement = (payload) => {
  setData('itemMeasurement', payload)
}

export const addItemMeasurementRaw = (payload) => {
  setData('itemMeasurementRaw', payload)
}

export const GetItemMeasurement = () => {
  return getData('itemMeasurement')
}

export const GetItemMeasurementRaw = () => {
  return getData('itemMeasurementRaw')
}

export const addItemCurrency = (payload) => {
  setData('itemCurrency', payload)
}

export const addItemCurrencyRaw = (payload) => {
  setData('itemCurrencyRaw', payload)
}

export const addBillingStatus = (payload) => {
  setData('billingStatus', payload)
}

export const addItemCategory = (payload) => {
  setData('itemCategory', payload)
}

export const addItemCategoryRaw = (payload) => {
  setData('itemCategoryRaw', payload)
}

export const GetItemCurrency = () => {
  return getData('itemCurrency')
}

export const GetItemCurrencyRaw = () => {
  return getData('itemCurrencyRaw')
}


export const GetItemCategory = () => {
  return getData('itemCategory')
}

export const GetItemCategoryRaw = () => {
  return getData('itemCategoryRaw')
}

export const GetBillingStatus = () => {
  return getData('billingStatus')
}

export const PriceFormat = (value, currency) => {
  return new Intl.NumberFormat('id-ID', {
    style: 'currency',
    maximumSignificantDigits: 21,
    currency: currency
  }).format(value)
}

export const addAddressType = (payload) => {
  setData('addresstype', payload)
}

export const getAddressType = () => {
  return getData('addresstype')
}

export const addAddressTypeRaw = (payload) => {
  setData('addresstypeRaw', payload)
}

export const getAddressTypeRaw = () => {
  return getData('addresstypeRaw')
}


export const addUserList = (payload) => {
  setData('userlist', payload)
}

export const getUserList = () => {
  return getData('userlist')
}

export const addUserType = (payload) => {
  setData('usertype', payload)
}

export const getUserType = () => {
  return getData('usertype')
}

export const addRole = (payload) => {
  setData('role', payload)
}

export const getRole = () => {
  return getData('role')
}

export const addRoleList = (payload) => {
  setData('rolelist', payload)
}

export const getRoleList = () => {
  return getData('rolelist')
}

export const addRoleManagerList = (payload) => {
  setData('roleManagerlist', payload)
}

export const getRoleManagerList = () => {
  return getData('roleManagerlist')
}

export const addUserManagerData = (payload) => {
  setData('userManagerData', payload)
}

export const getUserManagerData = () => {
  return getData('userManagerData')
}

export const addMitraData = (payload) => {
  setData('mitra', payload)
}

export const getMitraData = () => {
  const data = sessionStorage.getItem('mitra')
  return JSON.parse(data)
}

export const addMitraList = (payload) => {
  setData('mitraList', payload)
}

export const getMitraList = () => {
  return getData('mitraList')
}

export const addBankAccountData = (payload) => {
  setData('bankAccount', payload)
}

export const getBankAccountData = () => {
  return getData('bankAccount')
}

export const addBankAccountList = (payload) => {
  setData('bankAccountList', payload)
}

export const getBankAccountList = () => {
  return getData('bankAccountList')
}

export const addBankSelect = (payload) => {
  setData('bankSelect', payload)
}

export const getBankSelect = () => {
  return getData('bankSelect')
}

export const addBankSelectRaw = (payload) => {
  setData('bankSelectRaw', payload)
}

export const getBankSelectRaw = () => {
  return getData('bankSelectRaw')
}

export const addSubscriptionHistory = (payload) => {
 return setData('subsHistory', payload)
}
export const getSubscriptionHistory = () => {
 return getData('subsHistory')
}

export const addSubscriptionHistoryData = (payload) => {
 return setData('subsHistoryData', payload)
}
export const getSubscriptionHistoryData = () => {
 return getData('subsHistoryData')
}

export const addSubscriptionCustomer = (payload) => {
 return setData('subsCustomer', payload)
}

export const getSubscriptionCustomer = () => {
 return getData('subsCustomer')
}

export const addDashboard = (payload) => {
 return setData('dashboard', payload)
}

export const getDashboard = () => {
 try {
   const data = getData('dashboard')
   if (!data.length) {
     return {
      revenueStats: [],
      customerQty: 0,
      revenueYoy:  0,
      revenueMny: 0,
      mitra: 0,
      topSelling: {
        data: [],
        currency: 'idr',
        total: 0
      },
      realizeSell: {
        data: [],
        currency: 'idr',
        total: 0
      }
    }
   }

   return data
 } catch (error) {
   return {
      customerQty: 0,
      revenueYoy:  0,
      revenueMny: 0,
      request: 0,
      topSelling: {
        data: [],
        currency: 'idr',
        total: 0
      },
      realizeSell: {
        data: [],
        currency: 'idr',
        total: 0
      }
    }
 }
}

export const addGlobalTemplateData = (payload) => {
 return setData('globaltemplate', payload)
}

export const getGlobalTemplateData = () => {
 return getData('globaltemplate')
}

export const addGlobalTemplateList = (payload) => {
 return setData('globaltemplateList', payload)
}

export const getGlobalTemplateList = () => {
 return getData('globaltemplateList')
}

export const addGlobalTemplateOption = (payload) => {
 return setData('globaltemplateOption', payload)
}

export const getGlobalTemplateOption = () => {
 return getData('globaltemplateOption')
}

export const addDatalogList = (payload) => {
 return setData('DatalogList', payload)
}

export const getDatalogList = () => {
 return getData('DatalogList')
}

export const addDatalogData = (payload) => {
 return setData('DatalogData', payload)
}

export const getDatalogData = () => {
 return getData('DatalogData')
}

export const addDatalogParam = (payload) => {
 return setData('DatalogParam', payload)
}

export const getDatalogParam = () => {
 return getData('DatalogParam')
}

export const addVirtualAccountData = (payload) => {
  setData('VirtualAccount', payload)
}

export const getVirtualAccountData = () => {
  return getData('VirtualAccount')
}

export const addVirtualAccountList = (payload) => {
  setData('VirtualAccountListData', payload)
}

export const getVirtualAccountList = () => {
  const data = getData('VirtualAccountListData')
  return data
}

export const addMitraBankSelect = (payload) => {
  setData('bankMitraSelect', payload)
}

export const getMitraBankSelect = () => {
  return getData('bankMitraSelect')
}

export const addRouterData = (payload) => {
  setData('Router', payload)
}

export const getRouterData = () => {
  return getData('Router')
}

export const addRouterList = (payload) => {
  setData('RouterListData', payload)
}

export const getRouterList = () => {
  const data = getData('RouterListData')
  return data
}

export const addRouterSelect = (payload) => {
  setData('RouterListSelect', payload)
}

export const getRouterSelect = () => {
  const data = getData('RouterListSelect')
  return data
}

export const addPhoneCodeData = (payload) => {
  setData('PhoneCode', payload)
}

export const getPhoneCodeData = () => {
  return getData('PhoneCode')
}

export const addPhoneCodeList = (payload) => {
  setData('PhoneCodeListData', payload)
}

export const getPhoneCodeList = () => {
  const data = getData('PhoneCodeListData')
  return data
}

export const addGridPoleData = (payload) => {
  setData('GridPole', payload)
}

export const getGridPoleData = () => {
  return getData('GridPole')
}

export const addGridPoleList = (payload) => {
  setData('GridPoleListData', payload)
}

export const getGridPoleList = () => {
  const data = getData('GridPoleListData')
  return data
}

export const addIndoorDeviceData = (payload) => {
  setData('IndoorDevice', payload)
}

export const getIndoorDeviceData = () => {
  return getData('IndoorDevice')
}

export const addIndoorDeviceList = (payload) => {
  setData('IndoorDeviceListData', payload)
}

export const getIndoorDeviceList = () => {
  const data = getData('IndoorDeviceListData')
  return data
}

export const addIOutoorDeviceData = (payload) => {
  setData('OutdoorDevice', payload)
}

export const getOutdoorDeviceData = () => {
  return getData('OutdoorDevice')
}

export const addOutdoorDeviceList = (payload) => {
  setData('OutdoorDeviceListData', payload)
}

export const getOutdoorDeviceList = () => {
  const data = getData('OutdoorDeviceListData')
  return data
}

export const indoorDeviceTypeOption = () => {
  const data = getData('IndoorDeviceOption')
  return data
}

export const outdoorDeviceTypeOption = () => {
  const data = getData('outdoorDeviceOption')
  console.log('outdoor device', data)
  return data
}

export const indoorDeviceTypeOptionRaw = (payload) => {
  setData('IndoorDeviceOptionRaw', payload)
}

export const outdoorDeviceTypeOptionRaw = () => {
  const data = getData('outdoorDeviceOptionRaw')
  return data
}

export const addoutdoorDeviceOption = (payload) => {
  setData('outdoorDeviceOption', payload)
}

export const addIndoorDeviceOption = (payload) => {
  setData('IndoorDeviceOption', payload)
}

export const addoutdoorDeviceOptionRaw = (payload) => {
  setData('outdoorDeviceOptionRaw', payload)
}

export const addIndoorDeviceOptionRaw = (payload) => {
  setData('IndoorDeviceOptionRaw', payload)
}

export const addRouterSelected = (payload) => {
  setData('RouterSelected', payload)
}

export const getRouterSelected = () => {
  const data = getData('RouterSelected')
  return data
}

export const getGlobalTemplate = () => {
  const data = getData('globaltemplateoptions')
  return data
}

export const addGlobalTemplate = (payload) => {
  setData('globaltemplateoptions', payload)
}

export const getPaymentStatus = () => {
  const data = getData(statusPayment)
  return data
}

export const addPaymentStatus = (payload) => {
  setData(statusPayment, payload)
}

export const Indicator = (status) => {
    if (!status) {
      return (
        <div className="danger">Denied</div>
      )
    } else {
      return (
        <div className="success">Accept</div>
      )
    }
  }

export const getCustomerData = () => {
  const data = sessionStorage.getItem('customerData')
  return JSON.parse(data) || {
      id: '',
      name: '',
      ownerName: '',
      owner: '',
      firstname: '',
      lastname: '',
      fullname: '',
      namingSeries: '',
      ktp: '',
      mobileNo: 0,
      customergroup: '',
      creation: '',
      modified: '',
      modifiedBy: '',
      teritory: '',
      internalCustomer: '',
      isFrozen: false,
      enabled: ''
  }
}

export const decodeToken = (accessToken) => {
  try {
    const base64Url = accessToken.split('.')[1];
    if (base64Url === undefined) return null;
    const decodedValue = JSON.parse(window.atob(base64Url));

    return decodedValue;
  } catch (error) {
    console.log(error.message)
    return {
      id: '',
      name: '',
      email: ''
    }
  }
}

export const customer = (name,
    firstname, lastname, /*owner, customerType,*/
    namingSeries,
    email,
    ktp,
    mobileno,
    customergroup,
    enabled,
    salesTeam,
    item,
    province,
    city,
    subDistrict,
    urban,
    addressTitle,
    addressType,
    addressLine,
    primaryAddress,
    shippingAddress,
    category,
    manualCustomerId,
   /* manualBillId, */
    pppUser,
    pppPassword,
    ppn,
    mitra,
    description,
    discount,
    latitude,
    longitude
    ) => ({
    name,
    firstname,
    lastname,
    namingSeries,
    email,
    ktp,
    mobileNo: mobileno,
    customergroup,
    enabled,
    salesTeam,
    item,
    province,
    city,
    subDistrict,
    urban,
    addressTitle,
    addressType,
    addressLine,
    primaryAddress,
    shippingAddress,
    category,
    manualCustomerId,
    /* manualBillId, */
    pppUser,
    pppPassword,
    ppn,
    mitra,
    description,
    discount,
    latitude,
    longitude
})

export const customerPut = (name,
    firstname, lastname, /*owner, customerType,*/
    namingSeries,
    email,
    ktp,
    mobileno, customergroup,
    teritory,
    status,
    enabled,
    salesTeam,
    category,
    ppn,
    description,
    discount
    ) => ({
    name: name,
    firstname: firstname,
    lastname: lastname,
    namingSeries: namingSeries,
    email: email,
    ktp:ktp,
    mobileNo: mobileno,
    customergroup: customergroup,
    teritory: teritory,
    status: status,
    enabled: enabled,
    salesTeam,
    category,
    ppn,
    description,
    discount
})

export const permission = (
  username, role, create, read, update, deleted
  ) => ({
    userName: username,
    role: role,
    create: create,
    read: read,
    update: update,
    deleted: deleted
})

export const putPermission = (
  create, read, update, deleted
  ) => ({
    create: create,
    read: read,
    update: update,
    deleted: deleted
})

export const user = (username,
    email, password,
    firstname, lastname, enable, userType,
    organization
  ) => ({
    username: username,
    email: email,
    password: password,
    firstname: firstname,
    lastname: lastname,
    enabled: enable,
    userType: userType,
    organization: organization
})

export const userToken = (userId) => ({
  userId
})

export const billingAddress = (
    mitraid,
    customerId,
    name,
    addressTitle,
    addressType, addressLine,
    province, city, subDistrict,
    urban, isPrimaryAddress,
    isShippingAddress,
    latitude,
    longitude
  ) => ({
    mitraid,
    customerId,
    name: name,
  //  owner: owner,
    addressTitle: addressTitle,
    addressType: addressType,
    addressLine: addressLine,
    province: province,
    city: city,
    subDistrict: subDistrict,
    urban: urban,
    isPrimaryAddress: isPrimaryAddress,
    isShippingAddress: isShippingAddress,
    latitude,
    longitude
})

export const billingAddressUpdate = (
    customerId,
    name,
    addressTitle,
    addressType, addressLine,
    province, city, subDistrict,
    urban, isPrimaryAddress,
    isShippingAddress,
    latitude, longitude
  ) => ({
    customerId,
    name: name,
  //  owner: owner,
    addressTitle: addressTitle,
    addressType: addressType,
    addressLine: addressLine,
    province: province,
    city: city,
    subDistrict: subDistrict,
    urban: urban,
    isPrimaryAddress: isPrimaryAddress,
    isShippingAddress: isShippingAddress,
    latitude, longitude
})

export const item = (mitraid, name, uplink, downlink,
    category,/* currency,*/
    price,  discount, Measurement, routerid, ip, subnet, pppProfileName
  ) => ({
    mitraid,
    name: name,
   // owner: owner,
    uplink: Number(uplink),
    downlink: Number(downlink),
    category: category,
    // currency: currency,
    price: Number(price),
    discount: Number(discount),
    measurement: Measurement,
    routerid, ip, subnet, pppProfileName
})

export const itemUpdate = (name, uplink, downlink,
    category,/* currency,*/
    price,  discount, Measurement, routerid, ip, subnet, pppProfileName
  ) => ({
    name: name,
   // owner: owner,
    uplink: Number(uplink),
    downlink: Number(downlink),
    category: category,
    // currency: currency,
    price: Number(price),
    discount: Number(discount),
    measurement: Measurement,
    routerid, ip, subnet, pppProfileName
})

export const router = (name, ipaddress, password, port, province, city, subDistrict, urban, merk, type, sn, description, latitude, longitude) => ({
    name, ipaddress, password, port: port.toString(), province, city, subDistrict, urban, merk, type, sn, description, latitude, longitude
})

export const routerAdd = (name, ipaddress, password, port, province, city, subDistrict, urban, merk, type, sn, description, mitra, latitude, longitude) => ({
    name, ipaddress, password, port: port.toString(), province, city, subDistrict, urban, merk, type, sn, description, mitra, latitude, longitude
})

export const billing = (mitraid, name, customer, item, address, status,/* manualBillId, */ pppUser, pppPassword, start, end) =>({
  mitraid,
  Name: name,
  Customer: customer,
  Item: item,
  Address: address,
  Status: status,
  /* manualBillId, */
  pppUser,
  pppPassword,
  start,
  end
})

export const putBilling = (mitraId, customer, item, address, status, generate, pppoeUser, pppoePassword, start, end) =>({
  mitraId,
  Customer: customer,
  Item: item,
  Address: address,
  Status: status,
  Generate: generate,
  start,
  end,
  pppoeUser,
  pppoePassword
})

export const sendEmail = (title, from, to, subject, text, phone, attachments, invoiceId) =>({
  invoiceId,
  title: title,
  from: from,
  to: to,
  subject: subject,
  text:text,
  phone,
  // phone: phoneDat.charAt(0).replace('0', '62') + phoneDat.slice(1),
  attachments: attachments
})

export const invoice = (mitraid, subs, desc, history = []) => ({
  mitraid,
  Name: subs,
  SubsHistory: history,
  Description: desc
})

export const putInvoice = (Paid, PaidType, dontSendNotification) => ({
  Paid,
  PaidType,
  dontSendNotification
})

export const setStatus = (active) => ({
  active: active
})

export const Mitra = (name, email, firstname, lastname, mobileNo, ktp, npwp, enabled, type, dueDay, ppn, beforeDue, administrationFee, province, latitude, longitude, instalationFee, memberFee) =>({
  Name: name,
  Email: email,
  Firstname: firstname,
  Lastname: lastname,
  mobileNo: mobileNo,
  ktp: ktp,
  npwp: npwp,
  enabled: enabled,
  type: type,
  dueDay,
  ppn,
  beforeDue,
  administrationFee,
  province,
  latitude,
  longitude,
  instalationFee,
  memberFee
})

export const MitraPut = (name, email, firstname, lastname, mobileNo, ktp, npwp, enabled, namingSeries, generateInvoice, generateTime, type, dueDay, ppn, beforeDue, administrationFee, maxMergeDate, maxMergePrice, latitude, longitude, instalationFee, memberFee, syncVa, syncHostpot, waGroupNotification, sendNotification, dynamicInvoiceDate, allowInvoiceClosing) =>({
  Name: name,
  Email: email,
  Firstname: firstname,
  Lastname: lastname,
  mobileNo: mobileNo,
  ktp: ktp,
  npwp: npwp,
  enabled: enabled,
  namingSeries: namingSeries,
  generateInvoice: generateInvoice,
  generateTime: generateTime,
  type: type,
  dueDay,
  ppn,
  beforeDue,
  administrationFee,
  maxMergeDate,
  maxMergePrice,
  latitude,
  longitude,
  instalationFee,
  memberFee,
  syncVa,
  syncHostpot,
  waGroupNotification,
  sendNotification,
  dynamicInvoiceDate,
  allowInvoiceClosing
})

export const MitraGenerate = (
    dynamicInvoiceDate,
    generateInvoice,
    generateTime,
    due,
    beforeDue,
    ppn,
    administrationFee,
    instalationFee,
    memberFee,
    maxMergeDate,
    maxMergePrice,
    syncVa,
    syncHostpot
) =>({
  dynamicInvoiceDate,
  generateInvoice,
  generateTime,
  due,
  ppn,
  beforeDue,
  administrationFee,
  instalationFee,
  memberFee,
  maxMergeDate,
  maxMergePrice,
  syncVa,
  syncHostpot
})

export const ResoureBasic = (name) => ({
  Name: name
})

export const ResoureGroup = (name, min, max) => ({
  Name: name,
  Min: min,
  Max: max
})

export const ResourceSymbol = (name, symbol) => ({
  Name: name,
  Simbol: symbol
})

export const BankAccount = (
  customerName,
  bankName,
  bankNumber,
  bankRegion,
  companyNumber,
  prefix,
  cid,
  sck,
  clientName,
  qauthAccessKey,
  qauthSecretKey,
  xApiKey,
  secretKey) => ({
  bankOwnerName: customerName,
  bankName,
  bankNumber,
  bankRegion,
  companyNumber,
  prefix,
  cid,
  sck,
  clientName,
  qauthAccessKey,
  qauthSecretKey,
  xApiKey,
  secretKey
})

export const VirtualAccount = (mitraid, customer, bankId, auto, netIdGenerate, number) => ({
  mitraid, customer, bankId, auto, netIdGenerate, number
})


export const ExportsCSV = (customer, mitra) => {
  return {
    customer,
    mitra
  }
}

export const GlobalTemplate = (name, type, header, body, footer, priority, warningNotification, overdue, paymentNotification, activeNotification, mitra) => ({
  name,
  type,
  header, body, footer, priority, warningNotification, overdue, paymentNotification, activeNotification, mitra
})

export const PhoneCode = (code, region, city, carrier, type) => ({
  code, region, city, carrier, type
})

export const GridPole = (name, routerid, height, latitude, longitude, description) => ({
  name, routerid, height, latitude, longitude, description
})

export const IndoorDevice = (name, routerid, deviceType, merk, sn, type, latitude, longitude, description) => ({
  name, routerid, deviceType, merk, sn, type, latitude, longitude, description
})

export const OutdoorDevice = (name, routerid, deviceType, latitude, longitude, description) => ({
  name, routerid, deviceType, latitude, longitude, description
})

export const renewInvoiceVa = (invoiceId) => ({
  invoiceId
})

export const addLogisticTypeOption = (payload) => {
  setData('logisticType', payload)
}
// Logistic Type
export const getLogisticTypeOption = () => {
 return getData('logisticType')
}

export const addLogisticTypenRaw = (payload) => {
  setData('logisticTypeRaw', payload)
}

export const getLogisticTypenRaw = () => {
 return getData('logisticTypeRaw')
}

// Logistic Item
export const addLogisticItemOption = (payload) => {
  setData('logisticItemOption', payload)
}

export const getLogisticItemOption = () => {
 return getData('logisticItemOption')
}

export const addLogisticItemData = (payload) => {
  setData('logisticItemData', payload)
}

export const getLogisticItemData = () => {
 return getData('logisticItemData')
}

export const addLogisticItemList = (payload) => {
  setData('logisticItemList', payload)
}

export const getLogisticItemList = () => {
 return getData('logisticItemList')
}

// Logistic Warehouse
export const addLogisticWarehouseOption = (payload) => {
  setData('logisticWarehouseOption', payload)
}

export const getLogisticWarehouseOption = () => {
 return getData('logisticWarehouseOption')
}

export const addLogisticWarehouseData = (payload) => {
  setData('logisticWarehouseData', payload)
}

export const getLogisticWarehouseData = () => {
 return getData('logisticWarehouseData')
}

export const addLogisticWarehouseList = (payload) => {
  setData('logisticWarehouseList', payload)
}

export const getLogisticWarehouseList = () => {
 return getData('logisticWarehouseList')
}

// Logistic Supplier
export const addLogisticSupplierOption = (payload) => {
  setData('logisticSupplierOption', payload)
}

export const getLogisticSupplierOption = () => {
 return getData('logisticSupplierOption')
}

export const addLogisticSupplierData = (payload) => {
  setData('logisticSupplierData', payload)
}

export const getLogisticSupplierData = () => {
 return getData('logisticSupplierData')
}

export const addLogisticSupplierList = (payload) => {
  setData('logisticSupplierList', payload)
}

export const getLogisticSupplierList = () => {
 return getData('logisticSupplierList')
}

// Logistic Goods
export const addLogisticGoodsData = (payload) => {
  setData('logisticGoodsData', payload)
}

export const getLogisticGoodsData = () => {
 return getData('logisticGoodsData')
}

export const addLogisticGoodsList = (payload) => {
  setData('logisticGoodsList', payload)
}

export const getLogisticGoodsList = () => {
 return getData('logisticGoodsList')
}

export const addLogisticGoodsCustomerData = (payload) => {
  setData('logisticGoodsCustomerData', payload)
}

export const getLogisticGoodsCustomerData = () => {
 return getData('logisticGoodsCustomerData')
}

export const addLogisticGoodsCustomerList = (payload) => {
  setData('logisticGoodsCustomerList', payload)
}

export const getLogisticGoodsCustomerList = () => {
 return getData('logisticGoodsCustomerList')
}

export const addLogisticMutationList = (payload) => {
  setData('logisticMutationList', payload)
}

export const getLogisticMutationList = () => {
 return getData('logisticMutationList')
}

export const LogisticItemAdd = (name, logisticType, measurement, sn, description, mitra) => ({
  name, logisticType, measurement, sn, description, mitra
})

export const LogisticItem = (name, logisticType, measurement, sn, description) => ({
  name, logisticType, measurement, sn, description
})

export const LogisticSupplier = (idManual, name, npwp, email, phone, address, mitra) => ({
  idManual, name, npwp, email, phone, address, mitra
})

export const LogisticSupplierPut = (name, npwp, email, phone, address) => ({
  name, npwp, email, phone, address
})

export const LogisticWarehouseAdd = (name, latitude, longitude, address, mitra) => ({
  name, latitude, longitude, address, mitra
})

export const LogisticWarehouse = (name, latitude, longitude, address) => ({
  name, latitude, longitude, address
})

export const LogisticGoods = (name, logisticItemId, supplierId, warehouseId, damaged, paid, price, qty, ppn, sn, description, dateCreation, manualId) => ({
  name, logisticItemId, supplierId, warehouseId, damaged, paid, price, qty, ppn, sn, description, dateCreation, manualId
})

export const LogisticGoodsPut = (name, logisticItemId, supplierId, warehouseId, damaged, price, paid, qty, ppn, description) => ({
  name, logisticItemId, supplierId, warehouseId, damaged, price, paid, qty, ppn, description
})

export const LogisticGoodsCustomer = (name, logisticItemId, supplierId, customerId, damaged, price, paid, qty, ppn, description) => ({
  name, logisticItemId, supplierId, customerId, damaged, price, paid, qty, ppn, description
})

export const WarehouseToCustomer = (customerId, snTarget, qtyChange, creation) => ({
  customerId, snTarget, qtyChange, creation
})

export const WarehouseToWarehouse = (warehouseDestId, snTarget, qtyChange, creation) => ({
  warehouseDestId, snTarget, qtyChange, creation
})

export const CustomerToWarehouse = (warehouseId, snTarget, qtyChange, creation) => ({
  warehouseId, snTarget, qtyChange, creation
})

export const baseBlob = (dataset) => ({
  dataset
})

export const snPackMap = (Sn, ManualId) => {
  let sn
  let manualId
  if (typeof Sn === 'string' && Sn !== '') {
    sn = Sn
  }
  if (typeof ManualId === 'string' &&ManualId !== '') {
    manualId = ManualId
  }
  return {
  sn, manualId
}}

export const LogisticGoodsBlob = (name, logisticItemId, supplierId, warehouseId, damaged, paid, price, Qty, ppn, snPack, description, dateCreation) => {
  const SnPack = !snPack.length ? [snPackMap(null, null)] : snPack
  const qty = (!Qty || Qty === 0) ? 1 : Qty
  return {
    name,
    logisticItemId,
    supplierId,
    warehouseId,
    damaged,
    paid,
    price,
    qty,
    ppn,
    snPack: SnPack.map((data) => {
      return {sn: data.sn, manualId: data.manualId}
    }),
    description,
    dateCreation
  }
}

export const InvoiceExportPayload = (mitra, period, type, filetype) => {
  return {
    mitra, period, type, filetype
  }
}

export const ItemExportPayload = (mitra, filetype) => {
  return {
    mitra, filetype
  }
}

export const putSubscriptionHistoryPayload = (discount) => ({
  discount
})

export const resendAllMessagePayload = (mitra, period) => ({
  mitra, period
})