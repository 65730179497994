/*eslint-disable*/
import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Row,
  Col,
  Button
} from "reactstrap";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions"
import { getRefreshToken, setStatus, addMitraData } from "variables/common";
import { customerOperation } from "Main/Utils";
import UserService from "Main/UserService";
import StatusButton from "components/Button/StatusButton";
import { useFormInput } from "Main/Utils";
import { addCustomerMitraList } from "variables/common";
import { getUserPermission } from "variables/common";
import { PriceFormat } from "variables/common";
import CommonList from "components/Table/CommonList";

function MitraList(props) {
  const pages = 'userManager'
  const currency = 'idr'
  const permission = getUserPermission()
  const Permission = permission.find(data => data.Role === pages) || ''
  const userService = new UserService()
  const mitraLen = useFormInput('ALL')
  const [load, setDataLoad] = useState(true)
  const [Table, setDataTable] = useState([])
  const refreshToken = getRefreshToken()

 try {
  const getMitra =  async() => {
    try {
      setDataLoad(true)
      const len = mitraLen.value
      const dat =  await userService.getAllMitra(len)
      const result = dat.data

      setDataState(mapping(result))
      setDataLoad(false)
      setDataTable(result)
      return result
    } catch (error) {
      return await CatchError(error, props, refreshToken)
    }
  }

  useEffect( () => {
    getMitra()
  }, [])

  const viewMitra = async(name)=> {
    try {
      const dat = await userService.getMitraData(name)
      const result = dat.data
      const len = 'ALL'
      const datCustomer =  await userService.getAllCustomerByMitra(name, 'c-active', len)
      const resultCustomer = datCustomer.data
      // console.log('customer', resultCustomer)
      addCustomerMitraList(resultCustomer)
      addMitraData(result)
      props.history.push('/admin/user-manager-mitra-view')
    } catch (error) {
      return await CatchError(error, props, refreshToken)
    }
  }

  const setMitraStatus = async(id, Status) => {
    try {
      const payload = setStatus(!Status)
      var operation = customerOperation(!Status)
      const dat = await userService.setMitraStatus(id, payload)
      swal(operation, dat.message, dat.status)
      const result = dat.data
      const table = await getMitra()
      setDataState(mapping(table))
      return result
    } catch (error) {
      swal('failed', 'Set Mitra status failed', 'warning')
    }
  }

  const deleteMitra = (name) => swal("Delete", "Data Tidak Dapat di Recover", "info", {
      buttons: {
        cancel: 'Cancel',
        delete: {text: "Delete", value: 'del' }
      }
    }
    ).then(async(value) => {
      switch (value) {
        case 'del':
          try {
            setDataLoad(true)
            const result = await userService.deleteMitra(name)
            await swal(result.status, result.message, 'success')
            const state = await getMitra()
            const stateRaw = mapping(state)
            const table = stateRaw.filter((dat) => dat.name !== name)
            setDataState(table)
            return true
          } catch (error) {
            setDataLoad(false)
            return await CatchError(error, props, refreshToken)
          }

        default:
          getMitra()
          return false
      }
    })

  const Add = () => {
      props.history.push('/admin/user-manager-mitra-add')
  }

  const mapping = (TableData) => TableData.map((Data, i) => {
        try {
          return {
            id: Data.mitraId,
            key: Data.refKey,
            name: Data.mitra_name,
            email: Data.mitraEmail,
            adminFee: PriceFormat(Data.administrationFee, currency),
            created: Data.created_by,
            modified: Data.modified_by,
            status: (
                <div className="actions-right">
                <StatusButton status={Data.enabled} disabled={Permission.Update} statusOnChange={async(e) =>{
                    // handleStatusChange(e, Data.id)
                    // let obj = dataState.find((o) => o.id === Data.mitraId);
                    await setMitraStatus(Data.mitraId, Data.enabled)
                  }}/>
                </div>
            ),
            actions: (
              // we've added some custom button actions
              <div className="actions-right" key={Data.id}>
                {/* use this button to add a edit kind of action */}
                <Button
                disabled={!Permission.Read}
                  onClick={() => {
                    // let obj = dataState.find((o) => o.id === Data.mitraId);
                    viewMitra(Data.mitra_name)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.mitraId}${i}`}
                  color="warning"
                  size="sm"
                >
                  <i className="fa fa-edit" />
                </Button>{" "}
                {/* <UncontrolledTooltip
                    delay={0}
                    target={`tooltip${Data.mitraId}${i}`}
                  >Update</UncontrolledTooltip> */}
                {/* use this button to remove the data row */}
                <Button
                disabled={!Permission.Delete}
                  onClick={() => {
                    deleteMitra(Data.mitra_name)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltips${Data.mitraId}${i}`}
                  color="danger"
                  size="sm"
                >
                <i className="fa fa-times" />
                </Button>{" "}
                {/* <UncontrolledTooltip
                    delay={0}
                    target={`tooltips${Data.mitraId}${i}`}
                  >Delete</UncontrolledTooltip> */}
              </div>
            ),
        };
        } catch (error) {
          console.log(error)
          return []
        }
  })

  const [dataState, setDataState] = useState(mapping(Table))

  const columns = [
                    {
                      Header: "Key",
                      accessor: "key",
                    },
                    {
                      Header: "Nama",
                      accessor: "name",
                    },
                    {
                      Header: "Email",
                      accessor: "email",
                    },
                    {
                      Header: "Created By",
                      accessor: "created",
                    },
                    {
                      Header: "Modified By",
                      accessor: "modified",
                    },
                    {
                      Header: "Biaya Admin",
                      accessor: "adminFee",
                    },
                    {
                       Header: "Status",
                       accessor: "status",
                       sortable: false,
                       filterable: false,
                    },
                    // {
                    //    Header: "Active",
                    //    accessor: "statustest",
                    //    sortable: false,
                    //    filterable: false,
                    // },
                    {
                      Header: "Update  Hapus",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]
  const menus = (
    <Row>
      <Col md={10}>
        <Button onClick={Add} color="info" disabled={!Permission.Create}>Add</Button>
      </Col>
    </Row>
  )

  return (
    <>
    <PanelHeader size="sm" />
      {/* <PanelHeader
            content={
              <div className="header text-center">
                <h2 className="title">List Mitra</h2>
              </div>
            }
          /> */}
      <div className="content">
        <CommonList
          key={1}
          header={'Mitra'}
          columns={columns}
          dataState={dataState}
          menu={menus}
          load={load}
          getData={getMitra} />
      </div>
    </>
  )
  } catch (error) {
    return (
      <>
      <PanelHeader
            content={
              <div className="header text-center">
                <h2 className="title">List Mitra</h2>
              </div>
            }
          />
      <h3>{error.message || error}</h3>
      </>
    )
  }
}

export default MitraList;
