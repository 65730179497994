import APIconsume from './APIconsume'
class LoginService {
    constructor(){
        this._consume = new APIconsume()
        this._path = 'auth/login'
        this._requestTokenPath = 'auth/key'
    }

    async requestToken(email, password) {
        const payload = {
            Email: email.value,
            Password: password.value
        }
        const result = await this._consume.postData(payload, this._requestTokenPath)
        return result.data
    }

    async login(usrIdentifier, key, otp) {
        const payload = {
            usrIdentifier, key, otp
        }
        console.log(payload)
        const result = await this._consume.postData(payload, this._path)
        return result.data
    }

   async verifyLoginPayload (email, pass) {
        if (email.lenght === 0 && pass.lenght === 0) {
            throw new Error('Email and Password Required')
        } else if (email.lenght === 0) {
            throw new Error('Email Required')
        } else if (pass.lenght === 0) {
            throw new Error('Password Required')
        }
    }
}



export default LoginService