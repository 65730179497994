import { getRefreshToken } from "variables/common"
import { addUserData } from "variables/common"
import { decodeToken } from "variables/common"
import { addCustomerGroup } from "variables/common"
import { addItemMeasurementRaw } from "variables/common"
import { addItemCurrency } from "variables/common"
import { addItemMeasurement } from "variables/common"
import { addOwner } from "variables/common"
import { addCustomerGroupRaw } from "variables/common"
import { addProvince } from "variables/common"
import { addUserSelect } from "variables/common"
import { addItemCurrencyRaw } from "variables/common"
import { addItemCategoryRaw } from "variables/common"
import { addAddressTypeRaw } from "variables/common"
import { addStatusOptions } from "variables/common"
import { addBillingStatus } from "variables/common"
import { addRole } from "variables/common"
import { addCustomerOptions } from "variables/common"
import { addBillingOptions } from "variables/common"
import { addBankSelect } from "variables/common"
import { addMitraBankSelect } from "variables/common"
import { addDatalogList } from "variables/common"
import { addRouterSelect } from "variables/common"
import { addIndoorDeviceOptionRaw } from "variables/common"
import { addPaymentStatus } from "variables/common"
import { addoutdoorDeviceOptionRaw } from "variables/common"
import { addoutdoorDeviceOption } from "variables/common"
import { addIndoorDeviceOption } from "variables/common"
import { addDatalogParam } from "variables/common"
import { addDashboard } from "variables/common"
import { addBankSelectRaw } from "variables/common"
import { addGlobalTemplateOption } from "variables/common"
import { addBankAccountList } from "variables/common"
import { addAddressOptions } from "variables/common"
import { addItemOptions } from "variables/common"
import { addUserType } from "variables/common"
import { addStatusOptionsRaw } from "variables/common"
import { addMitra } from "variables/common"
import { addAddressType } from "variables/common"
import { addItemCategory } from "variables/common"
import BankAccountService from "./BankAccountService"
import CustomerService from "./CustomerService"
import { FetchLogisticSelect } from "./Logistic/FetchLogistic"
import RouterService from "./RouterService"
import TemplateService from "./TemplateService"
import UserService from "./UserService"
import HostpotProfileService from "./HostpotVoucher/HostpotProfileService"
import { addhostpotProfileTimeMeasurementData } from "variables/HostpotVoucher/HostpotProfile"
import { addhostpotProfileInternetMeasurementData } from "variables/HostpotVoucher/HostpotProfile"
import { addUserPermission } from "variables/common"

const refreshToken = getRefreshToken()
const userService = new UserService()
const customerService = new CustomerService()
const bankAccountService = new BankAccountService()
const routerService = new RouterService()
const globalTemplateService = new TemplateService()
const hostpotProfileService = new HostpotProfileService()

const GlobalDataFetch = async (props) => {
  try {
    console.log('Global Data Fetch')
    const User = decodeToken(refreshToken);
    const userId = User.id
    const user = await userService.getUser(userId)
    const userData = user.data
    addUserData(userData)

    if (user.status === 401 && refreshToken) {
        // alert(`${response.status} ${response.statusText}`)
        try {
          const result = await userService.refreshUser(refreshToken)
          if (result.status === 201) {
            await childDataFetch(userData)
            return true
          }
          return false
        } catch (error) {
          console.log(error.message)
          props.history.push('/auth/login-page')
          return false
        }
    }
    await childDataFetch(userData)
    return true
  } catch (error) {
    console.log('Failed Global Datafetch', error.message)
    return false
  }
}

const childDataFetch = async (userData) => {
  const userId = userData.id
  const mitraName = userData.organization
  try {
    const userType = await customerService.getUserTypeSelect()
    addUserType(userType.data)
  } catch (error) {
    console.log(error.message)
  }
  try {
  const Owner = await customerService.getOwner()
    addOwner(Owner.data)
  } catch (error) {
    console.log(error.message)
  }
  try {
    const userSelect = await userService.getUserSelect()
    addUserSelect(userSelect.data)
  } catch (error) {
    console.log(error.message)
  }
  try {
    const userPermission = await userService.getAllPermission(userId)
    addUserPermission(userPermission.data)
  } catch (error) {
    console.log(error.message)
  }
  try {
    const templateOption = await globalTemplateService.getGlobalTemplateType()
    addGlobalTemplateOption(templateOption.data)
  } catch (error) {
    console.log(error.message)
  }

  try {
    const Getprovince = await customerService.getProvince()
    addProvince(Getprovince.data)
  } catch (error) {
    console.log(error.message)
  }

  try {
    const hostpotProfileTimeMeasurement = await hostpotProfileService.getTimeMeasurement()
    addhostpotProfileTimeMeasurementData(hostpotProfileTimeMeasurement.data)
  } catch (error) {
    console.log(error.message)
  }

  try {
    const hostpotProfileInternetMeasurement = await hostpotProfileService.getInternetMeasurement()
    addhostpotProfileInternetMeasurementData(hostpotProfileInternetMeasurement.data)
  } catch (error) {
    console.log(error.message)
  }

  try {
    const CustomerGroup = await customerService.getCustomerGroup()
    addCustomerGroup(CustomerGroup.data)
    addCustomerGroupRaw(CustomerGroup.raw)
  } catch (error) {
    console.log(error.message)
  }

  try {
    const role = await customerService.getRoleSelect()
    addRole(role.data)
  } catch (error) {
    console.log(error.message)
  }
  try {
    const statusOption = await customerService.getStatusSelect()
    addStatusOptions(statusOption.data)
    addStatusOptionsRaw(statusOption.raw)
  } catch (error) {
    console.log(error.message)
  }

  try {
    const ItemMeasurement = await customerService.getItemMeasurement()
    addItemMeasurement(ItemMeasurement.data)
    addItemMeasurementRaw(ItemMeasurement.raw)
  } catch (error) {
    console.log(error.message)
  }

  try {
    const itemCurrency = await customerService.getItemCurrency()
    addItemCurrency(itemCurrency.data)
    addItemCurrencyRaw(itemCurrency.raw)
  } catch (error) {
    console.log(error.message)
  }

  try {
    const itemCategory = await customerService.getItemCategory()
    addItemCategory(itemCategory.data)
    addItemCategoryRaw(itemCategory.raw)
  } catch (error) {
    console.log(error.message)
  }

  try {
    const addressType = await customerService.getAddressType()
    addAddressType(addressType.data)
    addAddressTypeRaw(addressType.raw)
  } catch (error) {
    console.log(error.message)
  }

  try {
    const paymentStatus = await customerService.getPaymentStatusSelect()
    addPaymentStatus(paymentStatus.data)
  } catch (error) {
    console.log(error.message)
  }

  try {
    const billingStatus = await customerService.getBillingStatusSelect()
    addBillingStatus(billingStatus.data)
  } catch (error) {
    console.log(error.message)
  }

  await FetchLogisticSelect()

  try {
    const mitra = await userService.getMitraData(mitraName)
    addMitra(mitra.data)
  } catch (error) {
    console.log(error.message)
  }

  try {
    const customerOption = await customerService.getCustomerSelect()
    addCustomerOptions(customerOption.data)
  } catch (error) {
    console.log(error.message)
  }

  try {
    const itemOption = await customerService.getItemSelect()
    addItemOptions(itemOption.data)
  } catch (error) {
    console.log(error.message)
  }

  try {
    const addressOption = await customerService.getAddressSelect()
    addAddressOptions(addressOption.data)
  } catch (error) {
    console.log(error.message)
  }

  try {
    const billingOption = await customerService.getBillingSelect()
    addBillingOptions(billingOption.data)
  } catch (error) {
    console.log(error.message)
  }

  try {
    const bankAccountList = await bankAccountService.getAllBankAccount()
    addBankAccountList(bankAccountList.data)
  } catch (error) {
    console.log(error.message)
  }

  try {
    const bankSelect = await customerService.getBankSelect()
    addBankSelect(bankSelect.data)
    addBankSelectRaw(bankSelect.raw)
  } catch (error) {
    console.log(error.message)
  }

  try {
    const bankMitraSelect = await bankAccountService.getBankAccountSelect()
    addMitraBankSelect(bankMitraSelect.data)
  } catch (error) {
    console.log(error.message)
  }

  try {
    const dashboard = await customerService.loadDashboard()
    addDashboard(dashboard.data)
  } catch (error) {
    console.log(error.message)
  }

  try {
    const datLog =  await userService.getDatalog()
    const logData = datLog.data
    addDatalogList(logData)
  } catch (error) {
    console.log(error.message)
  }

  try {
    const LogParam =  await userService.getDatalogParam()
    const logDataParam = LogParam.data
    addDatalogParam(logDataParam)
  } catch (error) {
    console.log(error.message)
  }

  try {
    const routerSelect = await routerService.getRouterSelect()
    addRouterSelect(routerSelect.data)
  } catch (error) {
    console.log(error.message)
  }

  try {
    const indoorDevice = await customerService.getIndoorDeviceSelect()
    const optIndoorData = indoorDevice.data
    const IndoorRaw = indoorDevice.raw
    addIndoorDeviceOption(optIndoorData)
    addIndoorDeviceOptionRaw(IndoorRaw)
  } catch (error) {
    console.log(error.message)
  }

  try {
    const outdoorDevice = await customerService.getOutdoorDeviceSelect()
    const optOutdoorData = outdoorDevice.data
    const OutdoorRaw = outdoorDevice.raw
    addoutdoorDeviceOption(optOutdoorData)
    addoutdoorDeviceOptionRaw(OutdoorRaw)
  } catch (error) {
    console.log(error.message)
  }
}

export default GlobalDataFetch