import React, { useState } from "react";
// import NotificationAlert from "react-notification-alert";
import swal from "sweetalert";
import { getUserData } from "variables/common";
import background from "assets/img/pattern3.jpg";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Label,
  Input,
  FormGroup,
  Button,
  CardFooter
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { Datestr } from "Main/Utils";
import UserService from "Main/UserService";
import { getRefreshToken } from "variables/common";
import { CatchError } from "Main/Exceptions/exceptions";
import LoadingScreen from "components/Loader/LoadingScreen";
import { addUserData } from "variables/common";

function User (props) {
    const userService = new UserService()
    const userData = getUserData()
    const [data, setData] = useState(userData)
    const { id, useTwoFA } = data

    const refreshToken = getRefreshToken()
    const [twoFA, setTwoFA] = useState([])
    const [twoFAKey, setTwoFAKey] = useState('')
    const [dataLoad, setDataLoad] = useState(false)
    const [isKeyFill, setisKeyFill] = useState(useTwoFA)

    const getTwoFactorAuth = async () => {
      setDataLoad(true)
      const user = await userService.getUser(id)
      const { data: dataUsr } = user
      addUserData(dataUsr)
      setData(dataUsr)
      if (!dataUsr.useTwoFA) {
        try {
          const result = await userService.getTwoFaQrCode()
          setTwoFA(result)
          setDataLoad(false)
        } catch (error) {
          setDataLoad(false)
          return await CatchError(error, props, refreshToken)
        }
      }
      setDataLoad(false)
    }

    const configTwoFA = async (otp) => {
      try {
        setDataLoad(true)
        const payload = {
          useQauth: !userData.useTwoFA, otp
        }
        const result = await userService.setTwoFA(payload)
        setTwoFA(result)
        setTwoFAKey('')
        setDataLoad(false)
        await swal(result.status, 'success', "success")
        await getTwoFactorAuth()
      } catch (error) {
        setDataLoad(false)
        await getTwoFactorAuth()
        return await CatchError(error, props, refreshToken)
      }
    }

    useState(() => {
      getTwoFactorAuth()
    }, [])

  const children = (
    <>
    {/* <NotificationAlert ref={notificationAlert} /> */}
      <PanelHeader size="sm"/>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <h5 className="title">Profile Saya</h5>
              </CardHeader>
              <CardBody>
                <Card className="card-user">
              <div className="image">
                <img alt="..." src={background}/>
              </div>
              <CardBody>
                <div className="author">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="avatar border-gray full-profile-lg img-thumbnail"
                      src= {userData.imgUrl || require("assets/img/pattern1.jpg")}
                    />
                    <h5 className="title">{userData.username}</h5>
                  </a>
                  <p className="description">{userData.fullName}</p>
                  <p className="description text-center">
                    Created {Datestr(userData.creation)} <br />
                    Last Updated {Datestr(userData.modified)}<br />
                  </p>
                </div>
              </CardBody>
              </Card>
                <div className="text-center">
                  <h6 className="title">Two Factor Authentication</h6>
                  {useTwoFA ? <label>Two Factor Authentication Aktif</label> : <img className = "" alt="No QR" src={twoFA.data}/>}
                </div>
                <Label>Qath Key</Label>
                <Input
                  value={twoFAKey}
                  placeholder="Two FA Key"
                  type="number"
                  disabled = {useTwoFA}
                  onChange={(e) => {
                    if (e.target.value.length <= 6) {
                      setTwoFAKey(e.target.value)
                      setisKeyFill(true)
                    }
                  }}
                />
              </CardBody>
              <CardFooter>
                <Button onClick = {async () => await configTwoFA(twoFAKey)} disabled = {!isKeyFill} color={useTwoFA ? "danger" : "info"}>{useTwoFA ? 'Nonaktif 2FA' : 'Atifkan 2FA'}</Button>
                <div className="text-center">
                  <h6 className="title">Profile</h6>
                </div>
                <Form>
                  <FormGroup>
                        <Label>Username</Label>
                        <Input
                          value={userData.username}
                          onChange = {() => {}}
                          placeholder="Username"
                          type="text"
                        />
                        <Label>Email</Label>
                        <Input
                          value={userData.email}
                          onChange = {() => {}}
                          placeholder="Email"
                          type="text"
                        />
                        <Label>Nama Depan</Label>
                        <Input
                          value = {userData.firstName}
                          onChange = {() => {}}
                          placeholder="Nama Depan"
                          type="text"
                        />

                        <Label>Nama Belakang</Label>
                        <Input
                          value = {userData.lastName}
                          onChange = {() => {}}
                          placeholder="Nama Belakang"
                          type="text"
                        />

                        <Label>Nama Lengkap</Label>
                        <Input
                          value = {userData.fullName}
                          onChange = {() => {}}
                          placeholder="Nama Lengkap"
                          type="text"
                        />

                        <Label>API key</Label>
                        <Input
                          value={userData.apiKey}
                          onChange = {() => {}}
                          placeholder="API Key"
                          type="text"
                        />

                        <Label>API Secret</Label>
                        <Input
                          value={userData.apiSecret}
                          onChange = {() => {}}
                          placeholder="API Secret"
                          type="password"
                        />

                        <Label>DocType</Label>
                        <Input
                          value={userData.docType}
                          onChange = {() => {}}
                          placeholder="Doctype"
                          type="text"
                        />
                        <Label>Organisasi</Label>
                        <Input
                          value={userData.organization}
                          onChange = {() => {}}
                          placeholder="Organisasi"
                          type="text"
                        />
                  </FormGroup>
                </Form>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );

  return (<>
      <LoadingScreen
        text = {'Loading'}
        loading = {dataLoad}
        children={children} />
  </>)
}

export default User;
